import { ControlledAccordion, useAccordionProvider } from "@szhsin/react-accordion";
import { useEffect, useState } from "react";
import { WButton } from "../../components/button/WButton.tsx";
import { WSearchField } from "../../components/forms/WSearchField.tsx";
import { AdminTitle } from "../AdminTitle.tsx";
import type { ValueEditorDefinition } from "./ConfigEditor.utils.ts";
import { validateInput } from "./ConfigEditor.utils.ts";
import ListValue from "./ListValue.tsx";
import { SingleValue } from "./SingleValue.tsx";

export function PlatformConfiguration() {
    const [searchTerm, setSearchTerm] = useState("");
    const [items, setItems] = useState<ValueEditorDefinition[]>([]);

    const providerValue = useAccordionProvider({
        allowMultiple: true,
        transition: true,
        transitionTimeout: 250,
    });
    const { toggleAll } = providerValue;

    const allItems: ValueEditorDefinition[] = [
        {
            type: "list",
            props: {
                name: "Setup - Auth0 settings",
                inputType: "textarea",
                path: "/auth",
                validationFun: validateInput("json"),
                description:
                    "Auth0 and MFA related settings. In most cases {{scope}} is DEFAULT. If reseller/theme specific configuration is needed, then {{scope}} must be reseller/theme name.",
                required: true,
                wikiLink: "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/22577189/Consul+Schemata#Auth",
                placeholder: JSON.stringify(
                    {
                        auth0_domain: "login.provider.io",
                        auth0_client_id: "Auth0 client ID from Dashboard/Applications/Provider",
                        auth0_client_secret: "Auth0 client secret from Dashboard/Applications/Provider",
                        oauth_callback_url: "Auth0 callback URL from Dashboard/Applications/Provider",
                        mfa_available: "(Not required) 1=enabled, 0=disabled",
                        management_api_domain:
                            "(Not required) Auth0 Domain from Dashboard/Applications/Provider, needed only when 2FA is enabled",
                        auth0_universal_login: '(Not required, False by default) "True" or "False"',
                    },
                    null,
                    2,
                ),
            },
        },
        {
            type: "single",
            props: {
                name: "Setup - Platform e-mail SMTP credentials",
                inputType: "textarea",
                inputRows: 6,
                path: "/email/smtp",
                validationFun: validateInput("json"),
                required: true,
                description: "SMTP credentials for sending e-mails.",
            },
        },
        /* {
            type: "list",
            props: {
                name: "Setup - Locations details",
                inputType: "textarea",
                path: "/locations",
                validationFun: validateInput("json"),
                description: "Datacenter details. {{scope}} is location slug.",
                required: false,
                wikiLink: "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/22577189/Consul+Schemata#Locations",
            },
        }, */
        {
            type: "single",
            props: {
                name: "Payments - Platform payment model (allow debt)",
                inputType: "input",
                path: "/payment/allow_debt",
                validationFun: validateInput("boolean"),
                description:
                    "Flag that indicates if UI and payment component should work in pre (false) or post (true, default) payment mode.",
                required: false,
                wikiLink: "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/22577189/Consul+Schemata#Allow-Debt",
                placeholder: "true",
            },
        },
        {
            type: "single",
            props: {
                name: "Payments - Platform payment processors list",
                inputType: "textarea",
                path: "/payment/processors/DEFAULT",
                validationFun: validateInput("json"),
                description: "List of payment methods and their public configuration.",
                required: true,
                wikiLink: "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/540770332/Configuration+in+Consul",
            },
        },
        {
            type: "single",
            props: {
                name: "Payments - Platform currency",
                inputType: "input",
                path: "/payment/currency",
                validationFun: validateInput("string"),
                description: "3-letter currency code that is used throughout the deployment.",
                required: true,
                wikiLink: "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/22577189/Consul+Schemata#Currency",
            },
        },
        {
            type: "list",
            props: {
                name: "Payments - Platform VAT percentage",
                inputType: "input",
                path: "/payment/vat",
                validationFun: validateInput("number"),
                description: "{{scope}} is either DEFAULT or a two letter ISO country code.",
                required: true,
                wikiLink:
                    "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/22577189/Consul+Schemata#VAT-Percentage",
            },
        },
        {
            type: "single",
            props: {
                name: "Payments - Payment emails themes",
                inputType: "textarea",
                path: "/payment/theme_conf/DEFAULT",
                validationFun: validateInput("json"),
                description: "Email theme configuration in JSON format",
                required: true,
                wikiLink:
                    "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/22577189/Consul+Schemata#Payment-theme-config",
            },
        },
        {
            type: "single",
            props: {
                name: "Payments - Recurring Payments enabled",
                inputType: "input",
                path: "/payment/recurring",
                validationFun: validateInput("boolean"),
                description: "Enable / Disable recurring payments",
                required: false,
                placeholder: "true",
            },
        },
        {
            type: "list",
            props: {
                name: "Payments - E-mail and PDF translations",
                inputType: "textarea",
                inputRows: 10,
                path: "/payment/translations",
                validationFun: validateInput("json"),
                description: "Translations to override default texts. {{scope}} is two letter ISO 639‑1 language code.",
                required: false,
                wikiLink:
                    "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/543031297/Add+translations+for+payment+e-mails+PDFs",
            },
        },
        {
            type: "single",
            props: {
                name: "VM size - Predefined packages",
                inputType: "textarea",
                path: "/ui/predefined_packages/vm",
                validationFun: validateInput("json"),
                description: "Configure different sized VM packages that are offered to users.",
                wikiLink:
                    "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/22577189/Consul+Schemata#VM-size---Predefined-packages",
                required: false,
                placeholder:
                    "Check Wiki for the default JSON, default packages for...\nLinux:\n  1 CPU, 0.5 GB RAM, 20 GB disk\n  1 CPU, 1 GB RAM, 30 GB disk\n  2 CPU, 2 GB RAM, 40 GB disk\n  2 CPU, 4 GB RAM, 60 GB disk\nWindows:\n  2 CPU, 2 GB RAM, 40 GB disk\n  2 CPU, 4 GB RAM, 60 GB disk\n  4 CPU, 8 GB RAM, 120 GB disk\n  8 CPU, 16 GB RAM, 240 GB disk",
            },
        },
        {
            type: "single",
            props: {
                name: "VM size - Disk size restrictions",
                inputType: "textarea",
                path: "/api/parameters/vm/disks",
                validationFun: validateInput("json"),
                description: "Configure min and max disk sizes for VM's on platform. Can be configured per OS type.",
                required: false,
                placeholder: JSON.stringify(
                    {
                        min: 20,
                        max: 1024,
                        limits: [{ os_name: "windows", min: 40 }],
                    },
                    null,
                    2,
                ),
            },
        },
        {
            type: "single",
            props: {
                name: "VM size - RAM size restrictions",
                inputType: "textarea",
                path: "/api/parameters/vm/ram",
                validationFun: validateInput("json"),
                required: false,
                description: "Configure min and max RAM sizes for VM's on platform. Can be configured per OS type.",
                placeholder: JSON.stringify(
                    {
                        min: 512,
                        max: 65536,
                        limits: [{ os_name: "windows", min: 1024 }],
                    },
                    null,
                    2,
                ),
            },
        },
        {
            type: "single",
            props: {
                name: "VM size - CPU size restrictions",
                inputType: "textarea",
                path: "/api/parameters/vm/vcpu",
                validationFun: validateInput("json"),
                required: false,
                description: "Configure min and max CPU sizes for VM's on platform. Can be configured per OS type.",
                placeholder: JSON.stringify(
                    {
                        min: 1,
                        max: 16,
                        limits: [{ os_name: "windows", min: 2 }],
                    },
                    null,
                    2,
                ),
            },
        },
        {
            type: "single",
            props: {
                name: "VM backup time",
                inputType: "input",
                path: "/ui/backup_time",
                required: false,
                description:
                    "This replaces the VM backup frequency description 'Once a week (Wednesday at 02:00 UTC)' in VM details view",
                placeholder: "Once a week (Wednesday at 02:00 UTC)",
            },
        },
        {
            type: "single",
            props: {
                name: "Disks trashing - days to keep in trash",
                inputType: "input",
                path: "/storage/disks_trashing/keep_in_trash_days",
                validationFun: validateInput("number"),
                description:
                    "Number of days to keep a deleted disk in trash before it is irrecoverably removed. This number applies at the moment of deletion when a 'purge' date is set.",
                required: false,
                placeholder: "14",
            },
        },
        {
            type: "single",
            props: {
                name: "Disks trashing - bypass hours",
                inputType: "input",
                path: "/storage/disks_trashing/bypass_trash_younger_than_hours",
                validationFun: validateInput("number"),
                description:
                    "Number of hours, a disk being deleted that is younger than this is not trashed, it goes straight to oblivion.",
                required: false,
                placeholder: "4",
            },
        },
        {
            type: "single",
            props: {
                name: "Disks trashing - cooldown seconds after rbd rm",
                inputType: "input",
                path: "/storage/disks_trashing/cooldown_after_rbd_rm_seconds",
                validationFun: validateInput("number"),
                description: "Number of seconds to wait after 'rbd rm' to not spam Ceph with heavy operations.",
                required: false,
                placeholder: "0",
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Site name",
                inputType: "input",
                path: "/ui/site_name",
                validationFun: validateInput("string"),
                description: "Name of the site.",
                required: true,
                placeholder: "Warren",
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Support email",
                inputType: "input",
                path: "/ui/support_email",
                validationFun: validateInput("string"),
                description: "Support email.",
                required: true,
                placeholder: "support@warren.io",
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Login title",
                inputType: "input",
                path: "/ui/login_title",
                validationFun: validateInput("string"),
                description: "Login page title.",
                placeholder: "",
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Login subtitle",
                inputType: "input",
                path: "/ui/login_subtitle",
                validationFun: validateInput("string"),
                description: "Login page subtitle.",
                placeholder: "",
            },
        },
        {
            type: "single",
            props: {
                name: "UI - S3 file browser, force path style urls",
                inputType: "input",
                path: "/ui/s3_force_path_style",
                validationFun: validateInput("boolean"),
                description:
                    "Defines if bucket name inside object urls are represented as subdomain or as part of the path. If set to true, bucket name is part of path. I set tof false, bucket name is a subdomain. Default: true",
                required: false,
                placeholder: "true",
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Platform default language",
                inputType: "input",
                path: "/ui/default_language",
                description: "2-letter language code that is used throughout the deployment. Default: en",
                required: false,
                placeholder: "en",
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Enable multi language support",
                inputType: "input",
                path: "/ui/multi_language",
                validationFun: validateInput("boolean"),
                description: "Enable multi language support for UI. Default: false",
                required: false,
                placeholder: "false",
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Provider support portal URL",
                inputType: "input",
                path: "/ui/support_link_url",
                validationFun: validateInput("string"),
                required: false,
                description: "URL to the support portal of the provider.",
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Onboarding details - User account details fields ",
                inputType: "textarea",
                path: "/config/user/detail_block_fields",
                validationFun: validateInput("json"),
                description:
                    "Extra required fields for user account details block on register, First name, Last name and Email are shown anyway",
                wikiLink:
                    "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/22577189/Consul+Schemata#User-Details-Fields",
                required: false,
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Onboarding details - Billing account details fields",
                inputType: "textarea",
                inputRows: 4,
                path: "/payment/billing_account_fields_config",
                validationFun: validateInput("json"),
                description: "Extra required fields for billing account details block on register.",
                wikiLink:
                    "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/22577189/Consul+Schemata#Billing-Account-Fields'-Configuration",
                required: false,
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Onboarding details - Billing account fields in New UI",
                inputType: "textarea",
                path: "/payment/billing_account_fields",
                validationFun: validateInput("json"),
                description:
                    "For configuring which billing account fields are visible and of those which are required when creating or modifying an account.",
                wikiLink:
                    "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/22577189/Consul+Schemata#Billing-Account-Fields-(for-new-UI)",
                required: false,
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Onboarding details - Add payment method later",
                inputType: "input",
                path: "/payment/add_payment_method_later",
                validationFun: validateInput("boolean"),
                description:
                    "Enable/disable option to add payment method later on onboarding page. Works only for post-payment mode.",
                required: false,
                placeholder: "true",
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Terms of Use Agreement URL's",
                inputType: "textarea",
                path: "/ui/terms/DEFAULT",
                validationFun: validateInput("json"),
                description: "Terms of Use Agreement related settings on register.",
                required: true,
                wikiLink:
                    "https://warrenio.atlassian.net/wiki/spaces/WAR/pages/22577189/Consul+Schemata#Terms-of-Use-Agreement",
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Third party integration - Facebook Pixel ID",
                inputType: "input",
                path: "/ui/facebook_pixel_id",
                required: false,
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Third party integration - LinkedIn Partner ID",
                inputType: "input",
                path: "/ui/linkedin_partner_id",
                required: false,
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Third party integration - LinkedIn Conversion ID",
                inputType: "input",
                path: "/ui/linkedin_conversion_id",
                required: false,
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Third party integration - Google Analytics Key",
                inputType: "input",
                path: "/ui/google_analytics_key",
                required: false,
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Third party integration - Microsoft Clarity key",
                inputType: "input",
                path: "/ui/clarity_key",
                required: false,
            },
        },
        {
            type: "single",
            props: {
                name: "UI - Chat",
                inputType: "textarea",
                path: "/ui/chat",
                validationFun: validateInput("json"),
                description: "Chat plugin configuration",
                required: false,
            },
        },
        {
            type: "single",
            isSecret: true,
            props: {
                name: "UI - Chat Secret",
                inputType: "textarea",
                path: "/ui/chat__secret",
                validationFun: validateInput("json"),
                description: "Chat plugin",
                required: false,
            },
        },
    ];

    useEffect(() => {
        setItems(allItems);
    }, []);

    useEffect(() => {
        let filteredItems = allItems;
        if (searchTerm && searchTerm.length > 2) {
            const sTerm = searchTerm.toLowerCase();
            filteredItems = allItems.filter(
                (i: ValueEditorDefinition) =>
                    i.props.path.includes(sTerm) || i.props.name.toLowerCase().includes(sTerm),
            );
        }
        setItems(filteredItems);
    }, [searchTerm]);

    return (
        <>
            <AdminTitle title="Platform Configuration">
                <div className="flex gap-0.5em">
                    <WButton color="primary" size="xs" variant="ghost" action={() => toggleAll(true)}>
                        Expand All
                    </WButton>
                    <WButton color="primary" size="xs" variant="ghost" action={() => toggleAll(false)}>
                        Collapse All
                    </WButton>

                    <WSearchField
                        width="flex-grow ml-auto max-w-20em"
                        value={searchTerm}
                        onChange={(value) => setSearchTerm(value)}
                    />
                </div>
            </AdminTitle>

            <div className="p-2">
                <p className="color-muted">
                    These settings apply all over platform. Make sure you know what you are doing before making any
                    changes, changes apply once they are made and will affect end-users.
                </p>
            </div>

            <ControlledAccordion providerValue={providerValue}>
                {items.map((i: ValueEditorDefinition) => {
                    return i.type === "single" ? (
                        <SingleValue key={i.props.path} {...i.props} />
                    ) : (
                        <ListValue key={i.props.path} {...i.props} />
                    );
                })}
            </ControlledAccordion>
        </>
    );
}
